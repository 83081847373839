@import "/node_modules/slim-select/dist/slimselect.css";
.date-edit > .input-group {
  display: flex;
}
.date-edit > .input-group > .mdi {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  border: 1px solid;
  border-color: hsl(218, 81%, 51%);
  background-color: #FFF;
  color: #000;
  border-right: 0;
}
.theme-dark .date-edit > .input-group > .mdi {
  border-color: hsl(218, 100%, 71%);
}
.theme-dark .date-edit > .input-group > .mdi {
  background-color: #424242;
}
.theme-dark .date-edit > .input-group > .mdi {
  color: #FFF;
}
.file-edit .preview {
  max-width: 450px;
  width: auto;
}
.file-edit .error:not(.btn) {
  margin: 10px 0;
  color: hsl(0, 80%, 47%);
}
.theme-dark .file-edit .error:not(.btn) {
  color: hsl(0, 100%, 68%);
}
.file-edit .file-target {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.file-edit .file-target > .file-content {
  display: inline-flex;
  align-items: center;
  border: 1px solid;
  font-family: 'Open Sans', sans-serif;
  border-color: hsl(218, 81%, 51%);
  background-color: #FFF;
  color: #000;
}
.theme-dark .file-edit .file-target > .file-content {
  border-color: hsl(218, 100%, 71%);
}
.theme-dark .file-edit .file-target > .file-content {
  background-color: #424242;
}
.theme-dark .file-edit .file-target > .file-content {
  color: #FFF;
}
.file-edit .file-target > .file-content > div {
  padding: 5px 10px;
  box-sizing: border-box;
}
.file-edit .file-target > .file-content > .file-name {
  border-right: 1px solid;
  border-color: hsl(218, 81%, 51%);
}
.theme-dark .file-edit .file-target > .file-content > .file-name {
  border-color: hsl(218, 100%, 71%);
}
.file-edit .file-target > .file-content > .file-remove {
  cursor: pointer;
}
.file-edit .file-target > .file-thumb {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  border-color: hsl(218, 81%, 51%);
  background-color: #FFF;
  color: #000;
  font-family: 'Open Sans', sans-serif;
  border-top: 0;
  padding: 10px;
  box-sizing: border-box;
}
.theme-dark .file-edit .file-target > .file-thumb {
  border-color: hsl(218, 100%, 71%);
}
.theme-dark .file-edit .file-target > .file-thumb {
  background-color: #424242;
}
.theme-dark .file-edit .file-target > .file-thumb {
  color: #FFF;
}
.file-edit .file-target > .file-thumb img {
  max-width: 100%;
}
.file-edit .final-upload-data {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.file-edit .final-upload-data > div {
  border-color: hsl(218, 81%, 51%);
  background-color: #FFF;
  color: #000;
  display: inline-flex;
  align-items: center;
  border: 1px solid;
  font-family: 'Open Sans', sans-serif;
  padding: 5px 10px;
  box-sizing: border-box;
}
.theme-dark .file-edit .final-upload-data > div {
  border-color: hsl(218, 100%, 71%);
}
.theme-dark .file-edit .final-upload-data > div {
  background-color: #424242;
}
.theme-dark .file-edit .final-upload-data > div {
  color: #FFF;
}
.file-edit .final-upload-data > .preview {
  justify-content: center;
  border-bottom: 0;
  padding: 10px;
}
.file-edit .final-upload-data > .preview img {
  max-width: 100%;
  max-height: 100%;
}
.monaco-text-editor-container {
  width: 100%;
  min-height: 300px;
  display: flex;
  border: 1px solid transparent;
  border-color: hsl(218, 81%, 51%);
}
.theme-dark .monaco-text-editor-container {
  border-color: hsl(218, 100%, 71%);
}
.monaco-text-editor-container > [data-value-template] {
  display: none;
}
.rich-text-editor-container {
  width: 100%;
  display: flex;
  border: 1px solid transparent;
  border-color: hsl(218, 81%, 51%);
}
.theme-dark .rich-text-editor-container {
  border-color: hsl(218, 100%, 71%);
}
.rich-text-editor-container > .cke {
  width: 100%;
  height: 100%;
}
tsc-switchercolumns {
  display: grid;
  width: 100%;
  height: 300px;
  grid-template-areas: "sp sp sp" "lp cp rp";
  grid-template-rows: min-content 1fr;
  grid-template-columns: 1fr 50px 1fr;
  row-gap: 20px;
  column-gap: 5px;
}
tsc-switchercolumns > .control-panel {
  grid-area: cp;
  display: flex;
  flex-direction: column;
}
tsc-switchercolumns > .search-panel {
  grid-area: sp;
}
tsc-switchercolumns > .left-panel {
  grid-area: lp;
}
tsc-switchercolumns > .left-panel::before {
  content: "Dostupné";
}
tsc-switchercolumns > .right-panel {
  grid-area: rp;
}
tsc-switchercolumns > .right-panel::before {
  content: "Přiřazené";
}
tsc-switchercolumns > .right-panel,
tsc-switchercolumns > .left-panel {
  overflow: auto;
  display: flex;
  flex-direction: column;
  border: 1px solid transparent;
  border-color: hsl(218, 81%, 51%);
  position: relative;
}
.theme-dark tsc-switchercolumns > .right-panel,
.theme-dark tsc-switchercolumns > .left-panel {
  border-color: hsl(218, 100%, 71%);
}
tsc-switchercolumns > .right-panel::before,
tsc-switchercolumns > .left-panel::before {
  position: sticky;
  top: 0;
  backdrop-filter: blur(30px);
  display: inline-flex;
  padding: 5px 10px;
  z-index: 5;
  border-left: 1px solid transparent;
  border-bottom: 1px solid transparent;
  border-color: hsl(218, 81%, 51%);
}
.theme-dark tsc-switchercolumns > .right-panel::before,
.theme-dark tsc-switchercolumns > .left-panel::before {
  border-color: hsl(218, 100%, 71%);
}
tsc-switchercolumns tsc-SwitcherRow {
  display: flex;
  padding: 5px 10px;
  align-items: center;
}
tsc-switchercolumns tsc-SwitcherRow .btn {
  margin: 0 5px;
}
tsc-switchercolumns tsc-SwitcherRow:hover {
  background-color: hsla(0, 0%, 0%, 0.04);
}
.theme-dark tsc-switchercolumns tsc-SwitcherRow:hover {
  background-color: hsla(0, 0%, 100%, 0.08);
}
tsc-switchercolumns tsc-SwitcherRow[side-stable="1"] {
  opacity: 0.5;
}
.url-edit > .input-group {
  display: flex;
}
.url-edit > .input-group > .mdi {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  border: 1px solid;
  border-color: hsl(218, 81%, 51%);
  background-color: #FFF;
  color: #000;
  border-right: 0;
}
.theme-dark .url-edit > .input-group > .mdi {
  border-color: hsl(218, 100%, 71%);
}
.theme-dark .url-edit > .input-group > .mdi {
  background-color: #424242;
}
.theme-dark .url-edit > .input-group > .mdi {
  color: #FFF;
}
.url-edit > .input-group > .mdi.mdi-pencil {
  border-right: 0;
  cursor: pointer;
}
.url-edit > .input-group > .mdi.mdi-pencil:hover {
  background-color: #E0E0E0;
}
.theme-dark .url-edit > .input-group > .mdi.mdi-pencil:hover {
  background-color: #616161;
}
.url-edit > .input-group input:read-only {
  color: rgba(0, 0, 0, 0.38);
}
.theme-dark .url-edit > .input-group input:read-only {
  color: rgba(255, 255, 255, 0.5);
}
tsc-userthemeswitcher > .switcher-item {
  display: inline-block;
  position: relative;
  margin-right: 30px;
}
tsc-userthemeswitcher > .switcher-item > input {
  position: absolute;
  bottom: 12px;
  left: 5px;
  z-index: 1;
  width: auto;
}
tsc-userthemeswitcher > .switcher-item > input:checked + label {
  border-color: hsl(218, 81%, 51%);
}
.theme-dark tsc-userthemeswitcher > .switcher-item > input:checked + label {
  border-color: hsl(218, 100%, 71%);
}
tsc-userthemeswitcher > .switcher-item > label {
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  display: flex;
  border: 2px solid transparent;
  border-color: rgba(0, 0, 0, 0.12);
}
.theme-dark tsc-userthemeswitcher > .switcher-item > label {
  border-color: rgba(255, 255, 255, 0.18);
}
tsc-userthemeswitcher > .switcher-item > label > .bottom {
  position: absolute;
  bottom: 0;
  padding: 10px 5px;
  padding-left: 30px;
  width: 100%;
  background-color: hsl(0, 0%, 98%);
  color: rgba(0, 0, 0, 0.6);
}
.theme-dark tsc-userthemeswitcher > .switcher-item > label > .bottom {
  background-color: hsl(0, 0%, 6%);
}
.theme-dark tsc-userthemeswitcher > .switcher-item > label > .bottom {
  color: rgba(255, 255, 255, 0.7);
}
.tag-selector .tags {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.tag-selector .tag {
  background: #eee;
  border-radius: 3px 0 0 3px;
  color: black;
  display: inline-block;
  height: 26px;
  line-height: 26px;
  padding: 0 20px 0 23px;
  position: relative;
  margin: 0 10px 10px 0;
  text-decoration: none;
  -webkit-transition: color 0.2s;
  cursor: pointer;
}
.tag-selector .tag::before {
  background: #fff;
  border-radius: 10px;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
  content: '';
  height: 6px;
  left: 10px;
  position: absolute;
  width: 6px;
  top: 10px;
}
.tag-selector .tag::after {
  border-bottom: 13px solid transparent;
  border-left: 10px solid #eee;
  border-top: 13px solid transparent;
  content: '';
  position: absolute;
  right: -10px;
  top: 0;
}
.tag-selector .tag:hover,
.tag-selector .tag.selected {
  background-color: crimson;
  color: white;
}
.tag-selector .tag:hover::after,
.tag-selector .tag.selected::after {
  border-left-color: crimson;
}
.star-review-edit .fa {
  cursor: pointer;
  color: #000;
}
.theme-dark .star-review-edit .fa {
  color: #FFF;
}
.star-review-edit .fa.selected,
.star-review-edit .fa.hover {
  color: hsl(26, 90%, 50%);
}
.theme-dark .star-review-edit .fa.selected,
.theme-dark .star-review-edit .fa.hover {
  color: hsl(30, 100%, 50%);
}
.star-review-edit .fa.no-hover {
  color: #000;
}
.theme-dark .star-review-edit .fa.no-hover {
  color: #FFF;
}
.check-list-selector {
  border: 1px solid transparent;
  border-color: rgba(0, 0, 0, 0.12);
}
.theme-dark .check-list-selector {
  border-color: rgba(255, 255, 255, 0.18);
}
.check-list-selector .lines > .line {
  display: grid;
  grid-template-columns: 30px 1fr;
  grid-template-rows: 35px;
  border-bottom: 1px solid transparent;
  border-color: rgba(0, 0, 0, 0.12);
}
.theme-dark .check-list-selector .lines > .line {
  border-color: rgba(255, 255, 255, 0.18);
}
.check-list-selector .lines > .line:last-child {
  border-bottom: none;
}
.check-list-selector .lines > .line:hover,
.check-list-selector .lines > .line.selected {
  background-color: hsla(0, 0%, 0%, 0.04);
}
.theme-dark .check-list-selector .lines > .line:hover,
.theme-dark .check-list-selector .lines > .line.selected {
  background-color: hsla(0, 0%, 100%, 0.08);
}
.multi-select-combo {
  display: block;
}
.multi-select-combo select {
  display: none;
}
.ss-content .ss-search input {
  color: black;
}
.bg-image-edit {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(30px) saturate(180%);
  z-index: 15;
  display: flex;
  justify-content: center;
}
.bg-image-edit.hidden {
  display: none;
}
.image-edit .btn-group {
  display: flex;
  gap: 10px;
}
.editor-content {
  width: 90%;
  height: 90%;
  margin-top: 30px;
}
.theme-dark body #SfxPopper {
  color: black;
}
.data-grid > .table > .row > .col .checkbox {
  display: inline-flex;
  width: 25px;
  height: 25px;
  position: relative;
  top: 0;
  left: 0;
  height: 1.25rem;
  width: 1.25rem;
  background: #f1f5f8;
  border: 1px solid #b8c2cc;
  border-radius: 0.125rem;
}
.data-grid > .table > .row > .col .checkbox::after {
  content: '';
  position: absolute;
  display: none;
  left: 0.45rem;
  top: 0.18rem;
  width: 0.25rem;
  height: 0.6rem;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.data-grid > .table > .row > .col .checkbox::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 3rem;
  height: 3rem;
  margin-left: -0.85rem;
  margin-top: -0.85rem;
  background: #0082f3;
  border-radius: 2rem;
  opacity: 0.6;
  z-index: 99999;
  transform: scale(0);
}
.data-grid > .table > .row > .col .checkbox.true {
  background: #0082f3;
  border-color: #0082f3;
}
.data-grid > .table > .row > .col .checkbox.true::after {
  display: block;
}
.data-grid > .table > .row > .col .target-column-image {
  max-height: 150px;
  max-width: 100%;
}
.toolbar .group .btn.flat {
  padding: 10px 0;
  padding-right: 10px;
}
.data-grid {
  display: block;
}
.data-grid > .table {
  width: 100%;
  overflow: auto;
  display: grid;
  grid-template-rows: 30px;
  border: 1px solid transparent;
  border-color: rgba(0, 0, 0, 0.12);
}
.theme-dark .data-grid > .table {
  border-color: rgba(255, 255, 255, 0.18);
}
.data-grid > .table > .row {
  border-bottom: 1px solid transparent;
  border-color: rgba(0, 0, 0, 0.12);
  display: flex;
}
.theme-dark .data-grid > .table > .row {
  border-color: rgba(255, 255, 255, 0.18);
}
.data-grid > .table > .row.head {
  position: sticky;
  top: 0;
}
.data-grid > .table > .row.head > .col {
  opacity: 0.6;
}
.data-grid > .table > .row:last-child {
  border-bottom: none;
}
.data-grid > .table > .row > .col {
  padding: 5px 10px;
  border-right: 1px solid transparent;
  border-color: rgba(0, 0, 0, 0.12);
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: auto;
  margin-bottom: auto;
  box-sizing: border-box;
  white-space: nowrap;
}
.theme-dark .data-grid > .table > .row > .col {
  border-color: rgba(255, 255, 255, 0.18);
}
.data-grid > .table > .row > .col.first {
  position: sticky;
  left: 0;
  width: 65px;
  padding: 5px 0;
  backdrop-filter: blur(30px);
  z-index: 5;
  opacity: 1;
  height: 100%;
  margin: 0;
}
.data-grid > .table > .row > .col * {
  display: inline;
}
.data-grid > .table > .row.values:hover,
.data-grid > .table > .row.values.selected {
  background-color: hsla(0, 0%, 0%, 0.04);
}
.theme-dark .data-grid > .table > .row.values:hover,
.theme-dark .data-grid > .table > .row.values.selected {
  background-color: hsla(0, 0%, 100%, 0.08);
}
.data-grid > .row.values.empty-info {
  padding: 20px;
  border: 1px solid;
  border-top: 0;
  border-color: rgba(0, 0, 0, 0.12);
  opacity: 0.6;
}
.theme-dark .data-grid > .row.values.empty-info {
  border-color: rgba(255, 255, 255, 0.18);
}
.base-list {
  display: block;
}
.list-toolbar {
  display: flex;
  justify-content: start;
  align-items: center;
}
.list-toolbar > .group {
  height: 45px;
  display: flex;
  align-items: center;
}
.list-toolbar > .group > * {
  margin: 0 3px;
}
.list-toolbar > .group.language {
  margin-left: auto;
}
.list-toolbar .group + .group::before {
  content: " ";
  display: block;
  width: 1px;
  height: 30px;
  margin: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.12);
}
.theme-dark .list-toolbar .group + .group::before {
  background-color: rgba(255, 255, 255, 0.18);
}
.graph-editor {
  display: grid;
  grid-template-rows: 100px 1fr;
  height: 100%;
  width: 100%;
}
#mycanvas {
  display: block;
  /* prevents scrollbar */
  width: 100vw;
  height: 100vh;
}
.bg-image-edit {
  --color-background: 33,34,35;
  --color-foreground: 255,255,255;
  --color-foreground-100: rgba(var(--color-foreground), 1);
  --color-foreground-95: rgba(var(--color-foreground), 0.95);
  --color-foreground-90: rgba(var(--color-foreground), 0.9);
  --color-foreground-80: rgba(var(--color-foreground), 0.8);
  --color-foreground-70: rgba(var(--color-foreground), 0.7);
  --color-foreground-60: rgba(var(--color-foreground), 0.6);
  --color-foreground-50: rgba(var(--color-foreground), 0.5);
  --color-foreground-40: rgba(var(--color-foreground), 0.4);
  --color-foreground-30: rgba(var(--color-foreground), 0.3);
  --color-foreground-20: rgba(var(--color-foreground), 0.25);
  --color-foreground-15: rgba(var(--color-foreground), 0.2);
  --color-foreground-10: rgba(var(--color-foreground), 0.15);
  --color-foreground-5: rgba(var(--color-foreground), 0.075);
  --color-foreground-3: rgba(var(--color-foreground), 0.05);
  --color-foreground-1: rgba(var(--color-foreground), 0.02);
  --color-foreground-0: rgba(var(--color-foreground), 0);
  --color-background-100: rgba(var(--color-background), 1);
  --color-background-95: rgba(var(--color-background), 0.95);
  --color-background-90: rgba(var(--color-background), 0.9);
  --color-background-80: rgba(var(--color-background), 0.8);
  --color-background-70: rgba(var(--color-background), 0.7);
  --color-background-60: rgba(var(--color-background), 0.6);
  --color-background-50: rgba(var(--color-background), 0.5);
  --color-background-40: rgba(var(--color-background), 0.4);
  --color-background-30: rgba(var(--color-background), 0.3);
  --color-background-20: rgba(var(--color-background), 0.2);
  --color-background-15: rgba(var(--color-background), 0.15);
  --color-background-10: rgba(var(--color-background), 0.1);
  --color-background-5: rgba(var(--color-background), 0.05);
  --color-background-3: rgba(var(--color-background), 0.03);
  --color-background-1: rgba(var(--color-background), 0.01);
  --color-background-0: rgba(var(--color-background), 0);
}
.image-editor-mf * {
  box-sizing: content-box;
  word-wrap: normal;
}
.image-editor-mf.bg-image-edit {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(30px) saturate(180%);
  z-index: 15;
}
.image-editor-mf__editor-stage .manipulator {
  --size: 1.25em;
  position: absolute;
  left: 0;
  top: 0;
  outline: none;
  touch-action: none;
}
.image-editor-mf__editor-stage .manipulator.corner {
  width: var(--size);
  height: var(--size);
  z-index: 2;
  margin-left: calc(var(--size) * -0.5);
  margin-top: calc(var(--size) * -0.5);
  line-height: var(--size);
  border-radius: 50%;
  background-color: var(--color-foreground-100);
  box-shadow: 0 0 2px var(--color-background-50);
}
.image-editor-mf__editor-stage .manipulator.corner[data-direction=bl],
.image-editor-mf__editor-stage .manipulator.corner[data-direction=tr] {
  cursor: nesw-resize;
}
.image-editor-mf__editor-stage .manipulator.corner[data-direction=br],
.image-editor-mf__editor-stage .manipulator.corner[data-direction=tl] {
  cursor: nwse-resize;
}
.image-editor-mf__editor-stage .manipulator.edge {
  height: 1px;
  width: 1px;
  z-index: 1;
}
.image-editor-mf__editor-stage .manipulator.edge[data-direction=l],
.image-editor-mf__editor-stage .manipulator.edge[data-direction=r] {
  cursor: ew-resize;
  width: var(--size);
  margin-left: calc(var(--size) * -0.5);
  transform-origin: center 0;
}
.image-editor-mf__editor-stage .manipulator.edge[data-direction=t],
.image-editor-mf__editor-stage .manipulator.edge[data-direction=b] {
  cursor: ns-resize;
  height: var(--size);
  margin-top: calc(var(--size) * -0.5);
  transform-origin: 0 center;
}
@keyframes dialog {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.8);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}
@keyframes blockinglayer {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.MessageBox {
  display: flex;
  flex-direction: column;
  position: absolute !important;
  top: 47% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  margin: auto;
  max-height: 80%;
  width: 640px;
  min-width: 320px;
  max-width: 90%;
  z-index: 11100;
  overflow: hidden;
  outline: none;
  background-color: #FFF;
  box-shadow: var(--elevation-6);
  border: 0;
  border-radius: var(--radius-2);
  transition: all 0.2s ease-out;
  /* UserMsgBuilde Styles */
  /* UserMsgBuilde Styles - END */
  /* CryptoVersionInfoBuilder Styles */
  /* CryptoVersionInfoBuilder Styles - END*/
}
.theme-dark .MessageBox {
  background-color: #212121;
}
.MessageBox:active {
  transition-duration: 0.1s;
}
.MessageBox.animation {
  animation-name: dialog;
  animation-duration: 200ms;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}
.MessageBox.animation.animation-backwards {
  animation-direction: reverse;
  animation-timing-function: ease-in;
}
.MessageBox.blocked {
  z-index: 10900;
}
.MessageBox > div {
  padding: var(--base-3);
}
.MessageBox > div.ui-resizable-handle {
  padding: 0;
}
.MessageBox > * {
  box-sizing: border-box;
  -moz-user-select: text;
  -webkit-user-select: text;
  user-select: text;
}
.MessageBox > .Header {
  font-size: var(--font-size-h4);
  font-weight: 700;
  letter-spacing: -0.03ch;
  padding-bottom: 0 !important;
  flex: 0 0 auto;
}
.MessageBox > .Header + .Content {
  padding-top: var(--base);
}
.MessageBox .Content {
  font-size: var(--font-size-body);
  font-weight: 400;
  line-height: var(--line-height-text);
  overflow: auto;
  color: #000;
}
.theme-dark .MessageBox .Content {
  color: #FFF;
}
.MessageBox > .Content {
  flex: 1 1 auto;
  word-wrap: break-word;
}
.MessageBox > .Content > .SubMessage {
  flex: 1 1 auto;
}
.MessageBox > .Content > .SubMessage .submessage {
  width: 100%;
  padding: var(--base) var(--base-2);
}
.MessageBox > .Content > .SubMessage .submessage:not(:last-of-type) {
  margin-bottom: var(--base);
}
.MessageBox > .Content > .SubMessage .submessage.Warning {
  box-shadow: inset var(--stripe-width) 0 0 hsl(44, 100%, 50%);
}
.theme-dark .MessageBox > .Content > .SubMessage .submessage.Warning {
  box-shadow: inset var(--stripe-width) 0 0 hsl(48, 100%, 50%);
}
.MessageBox > .Content > .SubMessage .submessage.Information {
  box-shadow: inset var(--stripe-width) 0 0 hsl(203, 80%, 58%);
}
.theme-dark .MessageBox > .Content > .SubMessage .submessage.Information {
  box-shadow: inset var(--stripe-width) 0 0 hsl(200, 100%, 63%);
}
.MessageBox > .Content > .SubMessage .submessage.Error {
  box-shadow: inset var(--stripe-width) 0 0 hsl(0, 80%, 47%);
}
.theme-dark .MessageBox > .Content > .SubMessage .submessage.Error {
  box-shadow: inset var(--stripe-width) 0 0 hsl(0, 100%, 68%);
}
.MessageBox > .Content > .SubMessage .submessage.Recommendation {
  box-shadow: inset var(--stripe-width) 0 0 hsl(44, 100%, 50%);
}
.theme-dark .MessageBox > .Content > .SubMessage .submessage.Recommendation {
  box-shadow: inset var(--stripe-width) 0 0 hsl(48, 100%, 50%);
}
.MessageBox > .DetailInformation {
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  width: 100%;
  padding: 0 !important;
  overflow: hidden;
}
.MessageBox > .DetailInformation > .ShowDetail {
  padding: 0 var(--base-3);
  margin-bottom: var(--base-2);
  cursor: pointer;
  text-align: right;
}
.MessageBox > .DetailInformation > .ShowDetail:hover {
  text-decoration: underline;
}
.MessageBox > .DetailInformation > .cc {
  padding: 0 var(--base-3);
  box-sizing: border-box;
  overflow: auto;
  width: 100%;
  height: 100%;
  border-top: 1px solid;
  border-bottom: 1px solid;
  word-wrap: break-word;
  border-color: rgba(0, 0, 0, 0.12);
}
.theme-dark .MessageBox > .DetailInformation > .cc {
  border-color: rgba(255, 255, 255, 0.18);
}
.MessageBox > .DetailInformation > .cc > .Content {
  font-family: monospace;
  white-space: pre-wrap;
  margin: var(--base-2) 0;
  color: #000;
  -moz-user-select: text;
  -webkit-user-select: text;
  user-select: text;
}
.theme-dark .MessageBox > .DetailInformation > .cc > .Content {
  color: #FFF;
}
.MessageBox > .DetailInformation.hidden > .cc {
  height: 0;
  border-color: transparent;
}
.theme-dark .MessageBox > .DetailInformation.hidden > .cc {
  border-color: transparent;
}
.MessageBox > .DetailInformation.hidden > .cc > .Content {
  opacity: 0;
}
.MessageBox > .bottom-buttons {
  display: flex;
  flex-direction: row;
  padding: 0 var(--base-2) var(--base-2) var(--base-2);
}
.MessageBox > .bottom-buttons > .left-buttons {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  padding: 0;
}
.MessageBox > .bottom-buttons > .standard-buttons {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  flex: 0 0 auto;
  padding: 0;
  margin-left: auto;
}
.MessageBox > .bottom-buttons .btn {
  min-width: 96px;
}
.MessageBox > .bottom-buttons .btn:not(:last-child) {
  margin-left: var(--base);
}
.MessageBox > .bottom-buttons .btn.loading {
  padding-left: var(--base-4);
}
.MessageBox > .bottom-buttons .btn.loading::before {
  content: "";
  height: 16px;
  width: 16px;
  left: 8px;
  position: absolute;
  top: 8px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.5);
  border-left: 2px solid #FFF;
  border-radius: 50%;
  border-right: 2px solid rgba(255, 255, 255, 0.5);
  border-top: 2px solid #FFF;
  animation: rotate 1s infinite linear;
}
.MessageBox > .Content > .master-group-message > .msg-group > .inner-group.top .name {
  font-size: var(--font-size-h6);
  font-weight: 700;
  letter-spacing: -0.01ch;
}
.MessageBox > .Content > .master-group-message .msg-group {
  transition: all 0.2s ease-out;
  overflow: hidden;
}
.MessageBox > .Content > .master-group-message .msg-group:active {
  transition-duration: 0.1s;
}
.MessageBox > .Content > .master-group-message .msg-group.hidden {
  display: none;
}
.MessageBox > .Content > .master-group-message .msg-group > .inner-group.content {
  padding-left: var(--base-3);
  margin: var(--base) 0;
  transition: all 0.2s ease-out;
  font-size: var(--font-size-body);
  font-weight: 400;
}
.MessageBox > .Content > .master-group-message .msg-group > .inner-group.content:active {
  transition-duration: 0.1s;
}
.MessageBox > .Content > .master-group-message .msg-group .inner-group .name {
  font-size: var(--font-size-subhead);
  font-weight: 500;
}
.MessageBox > .Content > .master-group-message .msg-group .inner-group.top {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.MessageBox > .Content > .master-group-message .msg-group .inner-group.top > .arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "budicon-16";
  font-size: var(--icon-small-font-size);
  font-style: normal;
  font-weight: normal;
  width: var(--base-3);
  height: var(--base-3);
  color: rgba(0, 0, 0, 0.6);
}
.theme-dark .MessageBox > .Content > .master-group-message .msg-group .inner-group.top > .arrow {
  color: rgba(255, 255, 255, 0.7);
}
.MessageBox > .Content > .master-group-message .msg-group .inner-group.top > .arrow::before {
  transition: all 0.2s ease-out;
  content: "\e35b";
}
.MessageBox > .Content > .master-group-message .msg-group .inner-group.top > .arrow::before:active {
  transition-duration: 0.1s;
}
.MessageBox > .Content > .master-group-message .msg-group.closed > .inner-group.content {
  display: none;
}
.MessageBox > .Content > .master-group-message .msg-group.closed .inner-group.top > .arrow::before {
  transform: rotate(-90deg);
}
.MessageBox .user-msg-item {
  padding-left: var(--base-2);
}
.MessageBox .user-msg-item.warning {
  box-shadow: inset var(--stripe-width) 0 0 hsl(44, 100%, 50%);
}
.theme-dark .MessageBox .user-msg-item.warning {
  box-shadow: inset var(--stripe-width) 0 0 hsl(48, 100%, 50%);
}
.MessageBox .user-msg-item.information {
  box-shadow: inset var(--stripe-width) 0 0 hsl(203, 80%, 58%);
}
.theme-dark .MessageBox .user-msg-item.information {
  box-shadow: inset var(--stripe-width) 0 0 hsl(200, 100%, 63%);
}
.MessageBox .user-msg-item.error {
  box-shadow: inset var(--stripe-width) 0 0 hsl(0, 80%, 47%);
}
.theme-dark .MessageBox .user-msg-item.error {
  box-shadow: inset var(--stripe-width) 0 0 hsl(0, 100%, 68%);
}
.MessageBox .user-msg-item.recommendation {
  box-shadow: inset var(--stripe-width) 0 0 hsl(44, 100%, 50%);
}
.theme-dark .MessageBox .user-msg-item.recommendation {
  box-shadow: inset var(--stripe-width) 0 0 hsl(48, 100%, 50%);
}
.MessageBox > .Content .crypto-version-info-content .version-message-separator {
  padding-top: 1em;
}
#blockingLayer {
  background-color: hsla(0, 0%, 0%, 0.5);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11000;
  outline: none;
}
.theme-dark #blockingLayer {
  background-color: hsla(0, 0%, 20%, 0.5);
}
#blockingLayer.animation {
  animation-name: blockinglayer;
  animation-duration: 2000ms;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}
#blockingLayer.animation.animation-backwards {
  animation-direction: reverse;
  animation-timing-function: ease-in;
}
/*Dialog pro zadání hesla (pro šifrování a dešifrování záznamů)*/
.dialog-encryption .message {
  margin-bottom: var(--base-4);
}
.dialog-encryption .Caption.invalid {
  color: hsl(0, 80%, 47%);
}
.theme-dark .dialog-encryption .Caption.invalid {
  color: hsl(0, 100%, 68%);
}
.dialog-encryption .Caption.password-confirm {
  margin-top: 15px;
}
/*# sourceMappingURL=page.css.map */